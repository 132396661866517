import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col, Form, Row, List } from 'antd';
import { 
  AppstoreOutlined, 
  AuditOutlined, 
  ArrowDownOutlined, 
  ArrowUpOutlined,
  ClearOutlined 
} from '@ant-design/icons';
import { 
  RowStyled, 
  ColStyled, 
  ColStyledTwo, 
  ColStyledThree, 
  LabelStyled, 
  InputStyled, 
  SelectStyled, 
  ButtonStyled, 
  ButtonStyledTwo,
  ButtonStyledThree, 
  BoxButtonsStyled,
  ListStyled, 
  ItemStyled 
} from './styles';

import { actions as questionActions } from '../../redux/modules/question';

import * as questionSelectors from '../../redux/selectors/question';

import { getModulesbyFamilies } from '../../redux/api/question';

const proptypes = {
  loading: PropTypes.bool.isRequired, 
  getQuestions: PropTypes.func.isRequired,
  getFamilies: PropTypes.func.isRequired,
  filters: PropTypes.shape({
    term: PropTypes.string,
    families: PropTypes.number,
    module_id: PropTypes.number,
    page: PropTypes.number
  }),
  setQuestionFilters: PropTypes.func.isRequired,
};

const categoryIdOther = 6;

function SearchQuestion(props) {
  const history = useHistory();
  const {
    getQuestions,
    getFamilies,
    loading,
    filters,
    setQuestionFilters
  } = props;

  const [openFilters, setOpenFilters] = useState(false);
  const [questions, setQuestions] = useState({});
  const [families, setFamilies] = useState([]);
  const [modules, setModules] = useState([]);

  const filtersFormatter = useCallback((filters) => {
    const formatted = {};
    for (const key in filters) {
      if (filters[key] !== undefined && 
          filters[key] !== null && 
          filters[key] !== '') {
        formatted[`filter[${key}]`] = filters[key];
      }
    }
    return formatted;
  }, []);

  const handleChangeFamily = useCallback(async (value) => {
    try {
      const requestModules = await getModulesbyFamilies(value);
      const keys = Object.keys(requestModules);
      if (keys.length > 0) {
        const dynamicKey = keys[0];
        const arrayModules = requestModules[dynamicKey] || [];
        const comboModules = arrayModules.map(module => ({
          value: module.id,
          label: module.title
        }));
        setModules(comboModules);
      } else {
        setModules([]);
      }
    } catch (error) {
      console.error('Error loading modules:', error);
      setModules([]);
    }
  }, []);

  const loadQuestions = useCallback(async () => {
    if (!filters) {
      setQuestions({});
      return;
    }
    
    const filtersFormatted = filtersFormatter(filters);
    const params = {
      ...filtersFormatted,
      page: filters?.page || 1
    };
    
    try {
      await getQuestions(params, (questions) => {
        setQuestions(questions || {});
      });
    } catch (error) {
      console.error('Error loading questions:', error);
      setQuestions({});
    }
  }, [filters, getQuestions, filtersFormatter]);

  const handleSubmit = useCallback((values) => {
    setQuestionFilters({ ...values, page: 1 });
  }, [setQuestionFilters]);

  const handleClearForm = useCallback(() => {
    setQuestionFilters({
      term: '',
      families: undefined,
      module_id: undefined,
      page: 1
    });
  }, [setQuestionFilters]);

  const loadFamilies = useCallback(() => {
    getFamilies((families) => {
      if (!Array.isArray(families)) {
        console.warn('Families data is not an array');
        setFamilies([]);
        return;
      }

      const comboFamilies = families.map(family => ({
        value: family.familie_id,
        label: family.name
      }));
      setFamilies(comboFamilies);
    });
  }, [getFamilies]);

  const renderQuestionsList = () => {
    if (loading) {
      return (
        <Row>
          <Col span={22} style={{paddingLeft: '24px'}}>
            <p>Carregando...</p>
          </Col>
        </Row>
      );
    }

    const hasQuestions = questions?.data?.length > 0;
    const totalQuestions = questions?.total || 0;

    return (
      <>
        <Row>
          <ColStyledThree span={24}>
            {totalQuestions > 0 ? (
              <p>
                {totalQuestions} {totalQuestions > 1 ? 'questões' : 'questão'}
              </p>
            ) : !questions?.data ? (
              <p>Carregando...</p>
            ) : (
              <p>Nenhuma questão encontrada!</p>
            )}
          </ColStyledThree>
        </Row>
        {hasQuestions && (
          <Row>
            <Col span={24}>
              <ListStyled
                dataSource={questions.data}
                renderItem={item => (
                  <ItemStyled onClick={() => history.push(`/questions/${item.id_questao}`)}>
                    <List.Item.Meta 
                      avatar={item.codigo} 
                      description={item.enunciado?.length > 237 ? 
                        `${item.enunciado.substring(0, 237)}...` : 
                        item.enunciado} 
                    />
                  </ItemStyled>
                )}
                pagination={{
                  onChange: page => {
                    setQuestionFilters({ ...filters, page });
                  },
                  pageSize: questions?.per_page || 0,
                  total: questions?.total || 0,
                  current: filters?.page || 1,
                  showSizeChanger: false
                }}
              />
            </Col>
          </Row>
        )}
      </>
    );
  };

  useEffect(() => {
    loadQuestions();
  }, [loadQuestions]);

  useEffect(() => {
    if (filters?.families && filters.families !== categoryIdOther) {
      handleChangeFamily(filters.families);
    }
  }, [filters.families, handleChangeFamily]);

  useEffect(() => {
    loadFamilies();
  }, [loadFamilies]);

  return (
    <Formik 
      initialValues={filters || {}}
      enableReinitialize
    >
      {({ values, handleChange, setFieldValue }) => (
        <Form style={{ width: '100%' }}>
          <RowStyled gutter={[4, {sm: 0, xl: 20}]} align="middle">
            <Col xs={24} sm={24} xl={6}>
              <LabelStyled htmlFor='term'>Enunciado ou código</LabelStyled>
              <InputStyled
                id='term'
                name="term"
                value={values.term}
                onChange={handleChange}
                placeholder="Insira aqui..."
                allowClear
              />
            </Col>
            <ColStyled open={openFilters} xs={24} sm={24} xl={6}>
              <SelectStyled
                className='ant-select-customize-input'
                placeholder={<><AuditOutlined /> Certificacões...</>}
                options={families}
                value={values.families}
                onChange={(value) => {
                  setFieldValue('families', value);
                  setFieldValue('module_id', undefined);
                  
                  if(value && value !== categoryIdOther) {
                    handleChangeFamily(value);
                  } else {
                    setModules([]);
                  }
                }}
                allowClear
                />
              </ColStyled>
              <ColStyled open={openFilters} xs={24} sm={24} xl={6}>
                <SelectStyled
                className='ant-select-customize-input'
                placeholder={<><AppstoreOutlined /> Módulo...</>}
                value={values.module_id}
                options={modules}
                onChange={(value) => setFieldValue('module_id', value)}
                disabled={!modules.length}
                allowClear
                />
              </ColStyled>
              <Col xs={24} sm={24} xl={6}>
                <BoxButtonsStyled>
                  <ButtonStyled type="submit" onClick={() => handleSubmit(values)}>Buscar</ButtonStyled>
                  {(!!filters.term || !!filters.families || !!filters.module_id) && (
                    <ButtonStyledTwo onClick={handleClearForm}>
                      <ClearOutlined /> Limpar
                    </ButtonStyledTwo>
                  )}
                </BoxButtonsStyled>
              </Col>
              <ColStyledTwo xs={24} sm={24} xl={4}>
                <ButtonStyledThree type="default" onClick={() => setOpenFilters(!openFilters)}>
                {openFilters ? <><ArrowUpOutlined /> Menos filtros</> : <><ArrowDownOutlined /> Mais filtros</>}
                </ButtonStyledThree>
              </ColStyledTwo>
              </RowStyled>
              {renderQuestionsList()}
        </Form>
      )}
    </Formik>   
  );
}

SearchQuestion.propTypes = proptypes;

const mapStateToProps = (state, props) => ({
  loading: questionSelectors.isLoading(state, props),
  filters: questionSelectors.getFilters(state, props),
});
const mapDispatchToProps = (dispatch) => ({
  getQuestions: (params, next) =>
    dispatch(questionActions.getQuestions(params, next)),
  getFamilies: (next) =>
    dispatch(questionActions.getFamilies(next)),
  setQuestionFilters: (filters) =>
    dispatch(questionActions.setQuestionFilters(filters)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchQuestion);
